export const base_url = "https://api.schemesbook.com/";
export const stage_url = "https://api.schemesbook.com/schemes-book/";
export const onboarding_url = "https://api.schemesbook.com/onboarding/";
export const analytics_url = "https://api.schemesbook.com/analytics/";

export const sub_urls = {
  onboarding: {
    getToken: "onboarding/admin/token",
    getTOTP: "onboarding/otp/totp",
  },
  brands: {
    getBrandsByFilter: "brand/filter",
    brandsBaseUrl: "brand",
  },
  products: {
    getProductsByFilter: "product/filter",
    searchProductByKeyword: "product/search",
    productBaseUrl: "product",
    productAddMultiple: "product",
  },
  category: {
    getByFilter: "category/filter",
    getProductsByFilter: "product/filter",
    searchProductByKeyword: "product/search",
    productBaseUrl: "product",
  },
  schemes: {
    filter: "schemes/filter",
    search: "schemes/search",
    baseUrl: "schemes",
  },
  drop: {
    filter: "priceDrop/filter",
    search: "priceDrop/search",
    baseUrl: "priceDrop",
  },
  nlc: {
    baseUrl: "product-nlc",
    byProductId: "product-nlc/product",
    filter: "product-nlc/filter",
    filterV2: "v2/product-nlc/filter",
    searchV2: "v2/product-nlc/search",
    search: "product-nlc/search",
    multipleAdd: "product-nlc/multiple",
  },
  states: {
    baseUrl: "state",
    getStatesByFilter: "state/filter",
  },
  district: {
    baseUrl: "district",
    getDistrictsByFilter: "district/filter",
  },
  group: {
    search: "group/search",
    baseUrl: "group",
    getGroupsByFilter: "group/filter",
    getGroupsBySearch: "group/search",
  },
  usersSubscriptions: {
    baseUrl: "users-subscriptions",
    getUserSubscriptionsByFilter: "users-subscriptions/filters",
    invoice: "users-subscriptions/invoice",
  },
  subscription: {
    baseUrl: "subscription",
  },
  user: {
    getUsersByFilter: "users/filters",
    updateBussinessAddress: "user/business/address",
    baseUrl: "users",
    updateBusinessProfile: "user/business/profile",
  },
  post: {
    baseUrl: "post",
    getPostsByFilter: "post/filter",
  },
  post_share: {
    baseUrl: "posts_share",
    getPostsByFilter: "posts_share/filter",
  },
  notification: {
    tokenBaseUrl: "notification/topic",
    baseUrl: "notification",
    getNotifcationByFilter: "notification/filter",
    sendNotification: "notification/send",
  },
  master: {
    roles: "master/roles",
    address: "master/address",
  },
  files: {
    s3GetUrl: "files/s3/url",
    uploadFiles: "files",
    getFiles: "files",
    deleteFiles: "files",
  },
  event: {
    getEventByFilter: "event/filters",
    getUniqueEventByFilter: "event/unique",
    getEventCountByFilter: "event/count/filters",
  },
  complaints: {
    getComplaints: "connects",
    getComplaintsFilter: "connects/filters",
    getComplaintsById: "connects",
    addComplaints: "connects",
    addComplaintsPhoneNumber: "connects",
    resolveComplaints: "connects",
    uploadPhotoConnect: "master/upload",
  },
};

// const firebaseConfig = {
//     apiKey: "AIzaSyDJUTQPi3rCOoZP88DI3RebpHYpxaNq7BE",
//     authDomain: "schemes-book-admin.firebaseapp.com",
//     projectId: "schemes-book-admin",
//     storageBucket: "schemes-book-admin.appspot.com",
//     messagingSenderId: "608620614148",
//     appId: "1:608620614148:web:ab373d6f47a00114725b51"
// };

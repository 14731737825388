import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const ComplaintsApi = {
  GetComplaints: async (type) => {
    const response = await axios.get(
      stage_url + sub_urls.complaints.getComplaints,
      {
        headers: Headers.getHeaders(),
        params: { type },
      }
    );
    return response;
  },
  GetComplaintsByFilter: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.complaints.getComplaintsFilter,
      {
        params: {
          type: payload.type,
         page:payload.page,
         size:payload.size,
         brandId:payload.brandId,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetComplaintsById: async (bId) => {
    const response = await axios.get(
      stage_url + sub_urls.complaints.getComplaintsById + "/" + bId,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddComplaints: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.complaints.addComplaints,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddComplaintsMobileNumber: async (payload, mobile) => {
    const response = await axios.post(
      stage_url + sub_urls.complaints.addComplaintsPhoneNumber + "/" + mobile,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  ResolveComplaint: async (id, resolution) => {
    const response = await axios.put(
      stage_url +
        sub_urls.complaints.resolveComplaints +
        "/" +
        id +
        "/" +
        "resolve",
      null,
      {
        params: {
          resolution,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  uploadPhotoConnect: async (payload, type) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${stage_url}master/upload?type=` + type,
      headers: {
        ...Headers.getHeaders(),
        "Content-Type": "multipart/form-data",
      },
      data: payload,
    };

    return axios.request(config);
  },
};
